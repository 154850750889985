<template>
	<div class="home_header">
		<div class="web_header">
			<div class="cont">
			<router-link to="/">
				<img src="../assets/img/LOGO.png" class="logo" alt="">
			</router-link>
			<!-- <div class="lang">
				<div class="item" @click="change_lang('zh')">中文</div>
				<div class="item" @click="change_lang('jp')">日本語</div>
				<div class="item" @click="change_lang('en')">English</div>
			</div> -->
			<!-- <el-select v-model="lang" @change="change_lang">
				<el-option  label="中文" value="zh"></el-option>
				<el-option  label="日本語" value="jp"></el-option>
				<el-option  label="English" value="en"></el-option>
			</el-select> -->
			<el-dropdown @command="change_lang" style="margin-left:60px;">
			  <span class="el-dropdown-link" style="color: #fff;cursor: pointer;">
			    {{langs[lang]}}<i class="el-icon-arrow-down el-icon--right"></i>
			  </span>
			  <el-dropdown-menu slot="dropdown">
			    <el-dropdown-item command="zh">中文</el-dropdown-item>
			    <el-dropdown-item command="jp">日本語</el-dropdown-item>
			    <el-dropdown-item command="en">English</el-dropdown-item>
			  </el-dropdown-menu>
			</el-dropdown>
			<div class="flex-item"></div>
			<div class="header_menu">
				<div class="menu_left">
					<router-link to="/look_job">
						<div :class="{active:path=='/look_job'}">
							<!-- 找工作 -->
							{{ $t("header.ZGZ") }}
						</div>
					</router-link>
					<router-link to="/look_people">
						<div :class="{active:path=='/look_people'}">
							<!-- 寻找人才 -->
							{{ $t("header.XZRC") }}
						</div>
					</router-link>
					<router-link to="/home">
						<div :class="{active:path=='/home'}">
							<!-- 料金 -->
							{{ $t("header.LJ") }}
						</div>
					</router-link>
					<router-link to="/activity_list">
						<div :class="{active:path=='/activity_list'}">
							<!-- 活动 -->
							{{ $t("header.HD") }}
						</div>
					</router-link>
					<router-link to="/work?status=0" v-if="islogin==1">
						<div :class="{active:path=='/work'}">
							<!-- 发布工作 -->
							{{ $t("header.FBGZ") }}
						</div>
					</router-link>
					<!-- <router-link to="/add_activity" v-if="islogin==1">
						<div> -->
							<!-- 发布活动 -->
							<!-- {{ $t("header.FBHD") }}
						</div>
					</router-link> -->
					
					<template v-if="islogin==0">
						<!-- <router-link to="/login_QY">
							<div>								
								{{ $t("header.QYDL") }}
							</div>
						</router-link> -->
						<router-link to="/login">
							<div :class="{active:path=='/login'}">								
								{{ $t("header.DL") }}
							</div>
						</router-link>
					</template>
				</div>
				
				<template v-if="islogin==0">
					<div class="menu_right">
						<router-link to="/register">
							<div :class="isreg==1?'right_active':'right_btn'">
								<!-- 个人注册 -->
								{{ $t("header.GRZC") }}
							</div>
						</router-link>
						<router-link to="/register?type=1">
							<div :class="isreg==2?'right_active':'right_btn'">
								<!-- 企业注册 -->
								{{ $t("header.QYZC") }}
							</div>
						</router-link>
			
					</div>
				</template>
				<div class="header_img" v-if="islogin==1">
					<router-link to="/inform_list" style="position: relative;">
						<img src="../../src/assets/img/home_top_clock.png" class="clock" alt="" >
						<div class="noticenum" v-if="new_count>0">{{new_count}}</div>
					</router-link>
					<div class="user_box">
						<!-- <img src="../../src/assets/img/user_img.png" class="user" alt=""> -->
						<img :src="avatar_url" class="user" alt="">
						<div class="user_menu">
							<router-link to="/my" class="menu_list" v-if="user_role==0">
								<!-- 我的主页 -->
								{{ $t("header.WDZY") }}
							</router-link>
							<router-link to="/qy" class="menu_list" v-if="user_role==1">
								<!-- 我的主页 -->
								{{ $t("header.WDZY") }}
							</router-link>
							<router-link to="/activity">
								<div class="menu_list">
									<!-- 活动中心 -->
									{{ $t("header.HDZX") }}
								</div>
							</router-link>
							<!-- 企业 -->
							<router-link to="/work" v-if="user_role==1">
								<div class="menu_list">
									<!-- 任务管理 -->
									{{ $t("header.RWGL") }}
								</div>
							</router-link>
							<router-link to="/work" v-if="user_role==0">
								<div class="menu_list">
									<!-- 工作中心 -->
									{{ $t("header.GZZX") }}
								</div>
							</router-link>
							<!-- <router-link to="/my_guanz">
								<div class="menu_list">									
									{{ $t("header.WDGZ") }}
								</div>
							</router-link> -->
							<router-link class="menu_list" to="/forget" v-if="user_role==0">
								<!-- 修改密码 -->
								{{ $t("header.XGMM") }}
							</router-link>
							<router-link class="menu_list" to="/forget?type=1" v-if="user_role==1">
								<!-- 修改密码 -->
								{{ $t("header.XGMM") }}
							</router-link>
							<!-- <div class="menu_list"> -->
								<!-- 切换背景 -->
								<!-- {{ $t("header.QHBJ") }}
							</div> -->
							<div class="menu_list" @click="loginOut">
								<!-- 退出登录 -->
								{{ $t("header.TCDL") }}
							</div>
						</div>
					</div>
				</div>
			</div>
			</div>
		</div>
		<div class="container">
			<router-view @login="login" @tuichu="tuichu" @chat="openchart"></router-view>
		</div>
		
		<div class="last_bottom">
			<router-link to="/">
			<img src="../../src/assets/img/bottom_logo.png" class="logo" alt="">
			</router-link>
			<a href="https://unlockly.co.jp/hr-tech" class="bottom_text">
				ABOUT US
			</a>
			<router-link to="/yinsi" class="bottom_text">
				<!-- 隐私政策 -->
				{{ $t("header.YSZC") }}
			</router-link>
			<router-link to="/tiaokuan" class="bottom_text">
				<!-- 使用条款 -->
				{{ $t("header.SYTK") }}
			</router-link>
			<!-- <div class="bottom_text" @click="editInfo">
				
				{{$t("header.CLGRXX") }}
			</div> -->
			<router-link to="/jiaoyifa" class="bottom_text">
				<!-- 特定交易法表述 -->
				{{ $t("header.TDJYFBS") }}
			</router-link>
			<router-link to="/login" class="bottom_text" v-if="islogin==0">
				<!-- 登录 -->
				{{ $t("header.DL") }}
			</router-link>
		</div>
		<el-dialog title="" :visible="true" v-if="outerVisible" :before-close="close">
		    <iframe :src='"/chat/?isgroup=0&conversationID="+chatid' style="width: 100%;height: 600px;"></iframe>
		</el-dialog>
	</div>
</template>

<script>
	import TIM from 'tim-js-sdk';
	let tim=null,timer=null;
	let timof = function(event) {
		console.log(event);
	};
	export default {
		data() {
			return {
				outerVisible:false,
				islogin:0,
				user_role:0,
				avatar_url:'',
				chatid:'',
				lang:'jp',
				langs:{zh:'中文',jp:'日本語',en:'English'},
				isreg:'',
				path:'',
				new_count:0,
				
				msgnum:'',
				list:[],				
				alllist:[],
				timlogin:0,
				timready:0
			};
		},
		provide() {
			return {
				getConfig: () => ({
					msgnum: this.msgnum,
					list: this.list,
				}),
			};
		},
		
		async created() {
			// if(this.$local.get('t_k')){
			// 	this.islogin=1;
			// }
			
			
			if(this.$local.get('user_role')){
				this.user_role=this.$local.get('user_role');
			}
			if(this.$local.get('lang')){
				this.$i18n.locale = this.$local.get('lang');
				this.lang=this.$local.get('lang');
			}
			await this.getLogin();
			this.setreg();
			
			this.getNotice();
			this.noticeTimer();
		},
		watch:{
			$route: async function(newRouter,oldRouter) {
				// if(newRouter.fullPath=='/register'){
				// 	this.isreg=1;
				// }else if(newRouter.fullPath=='/register?type=1'){
				// 	this.isreg=2;
				// }else{
				// 	this.isreg='';
				// }
				this.setreg();
				this.getLogin();
				
				
			}
		},
		destroyed() {
			clearInterval(timer);
			console.log('销毁header');
		},
		//   方法
		methods: {
			
			async timLogin(){
				
				
				let res=await this.$request.get('/api/custauth/genTIMUserSig',{userID:this.userID});
				sessionStorage.setItem('userSig', res.data.userSig);
				sessionStorage.setItem('SDKAppID', res.data.sdkAppID);
			
				let userSig=res.data.userSig;
				let SDKAppID=res.data.sdkAppID;				
				
				if(this.timlogin==0){					
					this.userID=this.$local.get('user_id')+'';
					let options = {SDKAppID: SDKAppID};
					tim = TIM.create(options);
					//tim.setLogLevel(0); // 普通级别，日志量较多，接入时建议使用
					tim.setLogLevel(1); // release 级别，SDK 输出关键信息，生产环境时建议使用					
					tim.login({userID:this.userID, userSig:userSig}).then(()=>{
						console.log('im登录成功',this.userID,userSig);
						this.timlogin=1;
					});
				}
				
				
					tim.on(TIM.EVENT.SDK_READY, ()=>{
						console.log('TIMready');					
						if(this.timready==0){	
							tim.on(TIM.EVENT.CONVERSATION_LIST_UPDATED,(res)=>{
								this.list=res.data;
								console.log('会话更新',this.list);
							});					
							tim.on(TIM.EVENT.TOTAL_UNREAD_MESSAGE_COUNT_UPDATED, (res)=>{
								this.msgnum=res.data;
								console.log('未读数更新',this.msgnum);						
								
							});
							this.timready=1;
						}
					});
				
				
			},
			close(){
				this.outerVisible=false;
			},
			noticeTimer(){
				timer=setInterval(()=>{
					this.getNotice();
				},10000);
			},
			async getNotice(){
				if(this.islogin==1){
					let res=await this.$request.get('/api/operMessage/msgSearchStat');
					this.new_count=res.data.new_count;
				}
			},
			setreg(){
				this.path=this.$route.path;
				//console.log(this.$route.path);
				if(this.$route.fullPath=='/register'){
					this.isreg=1;
				}else if(this.$route.fullPath=='/register?type=1'){
					this.isreg=2;
				}else{
					this.isreg='';
				}
			},
			editInfo(){
				console.log(this.user_role);
				
				if(this.user_role==0){
					this.$router.push({path:'/my_msg'});
				}
				if(this.user_role==1){
					this.$router.push({path:'/QY_msg'});
				}
			},
			change_lang(lang){
				console.log(this.lang);
				this.lang=lang;
				this.$i18n.locale = lang;
				this.$local.set('lang',lang);
				location.reload();
			},
			openchart(obj){
				this.chatid=obj.user_id;
				this.outerVisible=true;
			},
			//获取登录信息
			async getLogin(){
				if(this.$local.get('t_k')){
					let urls={0:'/api/custuser/getLoginAuthInfo',1:'/api/bususer/getLoginAuthInfo'};
					let res=await this.$request.get(urls[this.user_role],{},{islg:0});
					this.islogin=1;
					this.$local.set('user_id',res.data.user_id);
					this.userID=res.data.user_id;
					this.$local.set('user_role',res.data.user_role);
					this.$local.set('avatar_url',res.data.avatar_url);
					this.$request.reg({userid:res.data.user_id,nickname:res.data.open_name,faceurl:res.data.avatar_url});
					this.user_role=res.data.user_role;
					this.avatar_url=res.data.avatar_url;
					setTimeout(()=>{
						this.timLogin();
					},300);
				}
				
				
			},
			//登录
			login(obj){
				this.islogin=1;
				console.log(obj);
				this.$local.set('user_role',obj.user_role);
				this.user_role=obj.user_role;
				this.getLogin();
			},
			
			//退出登录
			async loginOut(){
				
				
				
				let lang=this.$local.get('lang');
				this.$local.clear();
				sessionStorage.clear();
				this.$local.set('lang',lang);
				this.islogin=0;				
				if(this.timlogin==1){
					this.timlogin=0;					
					let promise = tim.logout();
					promise.then(function(imResponse) {
					  console.log('登出成功',imResponse.data); // 登出成功
					}).catch(function(imError) {
					  console.warn('logout error:', imError);
					});
					
				}
				if(this.user_role==1){
					this.$router.push({path:'/login_QY'});
				}else{
					this.$router.push({path:'/login'});
				}
				
			},
			tuichu(){
				let lang=this.$local.get('lang');
				this.$local.clear();
				sessionStorage.clear();
				this.$local.set('lang',lang);
				if(this.timlogin==1){
					this.timlogin=0;
					let promise = tim.logout();
					promise.then(function(imResponse) {
					  console.log('登出成功',imResponse.data); // 登出成功
					}).catch(function(imError) {
					  console.warn('logout error:', imError);
					});
					
				}
				this.islogin=0;
				
			},
		}
	};
</script>

<style lang="less" scoped>
	.noticenum{position: absolute;right: 0;top: 0;background-color: #B65664;border-radius: 20px;display: flex;align-items: center;justify-content: center;padding:0 5px;height: 20px;font-size: 12px;color: #fff;box-sizing: border-box;min-width: 20px;}
	body {
		height: 100%;
	}
	.container{min-height: 100vh;min-height: calc(100vh - 186px);}
	.home_header{
		
		min-height: 100vh;
		
		background: #212121;
		.last_bottom{
			
			width: 100%;
			height: 76px;
			background: #212121;
			display: flex;
			align-items: center;
			justify-content: center;
			.logo{
				width: 82px;
				height: 22px;
				margin: 0 60px;
			}
			.bottom_text{
				cursor: pointer;
				display: block;
				color: #fff;
				font-size: 14px;
				margin: 0 60px;
			}
		}
	}
	.web_header {
		
		// width: 1350px;
		min-height: 100px;
		background: #212121;
		
		padding: 25px;
		margin:0 auto;
		box-sizing: border-box;
		position: sticky;top: 0;left: 0;right: 0;z-index: 99;
		.cont{width: 1350px;margin:0 auto;display: flex;align-items: center;justify-content: space-between;}
		.lang{color: #fff;font-size: 12px;display: flex;
			.item{padding:0 10px;cursor: pointer;border-right: 1px solid #555;
				&:last-child{border-right: none;}
			}
		}
		.logo{
			width: 214px;
			height: 56px;
		}
		.header_menu{
			display: flex;
			align-items: center;
			.menu_left{
				display: flex;
				// padding-top: 10px;
				div{
					font-size: 14px;
					color: #fff;
					margin-left: 50px;
				}
				.active{color:#567BB6}
			}
			.menu_right{
				margin-left: 50px;
				.right_btn{
					min-width: 140px;
					padding:0 10px;
					height: 30px;
					border-radius: 3px;
					border: 2px solid #3897A3;
					text-align: center;
					line-height: 30px;
					font-size: 14px;
					color: #fff;
					margin-bottom: 15px;
					box-sizing: border-box;
				}
				.right_btn:hover{
					
					
					
					background-color: #567BB6;
					color: #fff;
					border-color:#567BB6;
				}
				.right_active{
					min-width: 140px;
					padding:0 10px;
					height: 30px;
					border-radius: 3px;
					// border: 2px solid #3897A3;
					text-align: center;
					line-height: 30px;
					font-size: 14px;
					background-color: #567BB6;
					color: #fff;
					margin-bottom: 15px;
				}
			}
			.header_img{
				display: flex;
				align-items: center;
				margin-left: 60px;
				.clock{
					width: 23px;
					height: 26px;
					margin-right: 18px;
					
				}
				.user{
					width: 39px;
					height: 39px;
					border-radius: 50%;
					object-fit: cover;
				}
				.user_box:hover{
					.user_menu{
						display: block;
					}
				}
				.user_box{
					width: 60px;
					height: 60px;
					position: relative;
					z-index: 9;
					display: flex;
					align-items: center;
					justify-content: center;
					.user_menu{
						display: none;
						position: absolute;
						top: 50px;
						left: 50%;
						margin-left: -60px;
						width: 120px;
						min-height: 121px;
						background: #FFFFFF;
						box-shadow: 0px 0px 17px 0px rgba(0,0,0,0.5);
						border-radius: 9px;
						overflow: hidden;
						.menu_list{
							display: block;
							height: 38px;
							// background-color: #567BB6;
							text-align: center;
							line-height: 38px;
							font-size: 14px;
							font-weight: bold;
							color: #2B2B2A;
						}
						.menu_list:hover{
							height: 38px;
							background-color: #567BB6;
							color: #fff;
							text-align: center;
							line-height: 38px;
							font-size: 14px;
							font-weight: bold;
						}
					}
				}
			}
			
		}
		
		
		
		// align-items: center;
	}
	
	
</style>
